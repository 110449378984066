// Here you can add other styles
.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.formRow {
  min-height: 60px;
}

.paddingRight5 {
  margin: 5px 5px 0 0;
}

.sidebar .nav-dropdown.open .nav-item {
  text-indent: 12px !important;
}

.sidebar .nav-dropdown-items .nav-item i {
  font-size: 6px;
}

.sidebar .nav-link.nav-dropdown-toggle {
  background: #1eb7ad !important;
}

.sidebar .nav-dropdown-items .nav-link.active,
.sidebar .nav-dropdown-items .nav-link.active i {
  color: #ffffff !important;
}

.sidebar .nav-dropdown-items .nav-link,
.sidebar .nav-dropdown-items .nav-link i {
  color: #12ffef !important;
}

.ant-modal-wrap {
  z-index: 1023 !important;
}

ul.nav-dropdown-items {
  background: rgba(0, 0, 0, 0.3);
}

.sidebar .nav-dropdown-items .unactive.active,
.sidebar .nav-dropdown-items .unactive.active i {
  color: #12ffef !important;
  background: transparent !important;
}

.unactive.active:hover {
  background: turquoise;
}

.nav-dropdown .unactive.active:hover {
  background: #01d9cb !important;
}