.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  min-height: 400px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #999999;
}
.rbc-off-range-bg {
  background: #e5e5e5;
}
.rbc-header {
  overflow: hidden;
  -ms-flex: 1 0;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #ddd;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-content {
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
.rbc-today {
  background-color: #eaf6ff;
}
.rbc-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}
.rbc-event {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected {
  background-color: #265985;
}
.rbc-event:focus {
  outline: 5px auto #3b99fc;
}
.rbc-event-label {
  font-size: 80%;
}
.rbc-event-overlaps {
  -webkit-box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}
.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0;
  flex: 1 0;
  width: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.rbc-month-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rbc-month-row {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0;
  flex: 1 0;
  -ms-flex-preferred-size: 0px;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}
.rbc-date-cell {
  -ms-flex: 1 1;
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex: 1 0;
  flex: 1 0;
  overflow: hidden;
}
.rbc-day-bg {
  -ms-flex: 1 0;
  flex: 1 0;
  display: block;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0;
  flex: 1 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #ddd;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ddd;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-time-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  -ms-flex: 1 1;
  flex: 1 1;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  -ms-flex: none;
  flex: none;
}
.rbc-label {
  padding: 0 5px;
}
.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-is-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-event-label {
  -ms-flex: none;
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  -ms-flex: 1 1;
  flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  -ms-flex: 1 0;
  flex: 1 0;
  -ms-flex-preferred-size: 0px;
  flex-basis: 0px;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  -ms-flex: 1 1;
  flex: 1 1;
  -ms-flex-preferred-size: 0 px;
  flex-basis: 0 px;
}
.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}
.rbc-time-slot {
  -ms-flex: 1 0;
  flex: 1 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}
.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
.rbc-slot-selecting {
  cursor: move;
}
.rbc-time-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 1;
  flex: 1 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 20px;
}
.rbc-time-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header-content {
  -ms-flex: 1 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -ms-flex-direction: column;
  flex-direction: column;
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0;
  flex: 1 0;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  -ms-flex: none;
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}
.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}
.calendar-container {
  height: 100%;
  background: #ffffff;
  padding: 20px 20px;
}
.calendar-container,
.calendar-container > * {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.ReactTable .rt-tbody .rt-td {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.input-group-prepend button.btn,
.input-group-append button.btn {
  z-index: 2;
}
.input-group .Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 999;
  -webkit-overflow-scrolling: touch;
}
.form-group .SingleDatePicker_picker {
  z-index: 999;
}
.full-loading {
  text-align: center;
  width: 100%;
}
a.none-style {
  text-decoration: none !important;
  color: white;
}
a.none-style:hover {
  text-decoration: none !important;
  color: white;
}
/* #00AFA5 */
#root .sidebar {
  background: #01b7ac;
}
#root .sidebar .nav-link .nav-icon {
  color: #ffffff;
}

#root .sidebar-minimized .sidebar .sidebar-minimizer,
#root .sidebar-minimized .sidebar .sidebar-minimizer {
  background-color: #01aea3 !important;
}
#root .sidebar .sidebar-minimizer {
  color: #ffffff;
}
@media only screen and (min-width: 992px) {
  #root .sidebar .nav-link.active,
  #root .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: #01d9cb;
  }
  #root .sidebar-minimized .sidebar .nav-item:hover > .nav-link,
  #root .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: #00afa4;
  }
  #root .sidebar .nav-link:hover {
    background: #01d9cb;
  }
}

/* select box */
.Select {
  position: relative;
}
.Select input::-webkit-contacts-auto-fill-button,
.Select input::-webkit-credentials-auto-fill-button {
  display: none !important;
}
.Select input::-ms-clear {
  display: none !important;
}
.Select input::-ms-reveal {
  display: none !important;
}
.Select,
.Select div,
.Select input,
.Select span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
  opacity: 0.35;
}
.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}
.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}
.Select.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
}
.Select.is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}
.Select.is-searchable.is-open > .Select-control {
  cursor: text;
}
.Select.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}
.Select.is-focused > .Select-control {
  background: #fff;
}
.Select.is-focused:not(.is-open) > .Select-control {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  background: #fff;
}
.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding-right: 42px;
}
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  color: #333;
}
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label,
.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:focus,
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:hover,
.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:hover {
  color: #007eff;
  outline: 0;
  text-decoration: underline;
}
.Select.has-value.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:focus,
.Select.has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  a.Select-value-label:focus {
  background: #fff;
}
.Select.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}
.Select .Select-arrow-zone:hover > .Select-arrow,
.Select.is-open .Select-arrow {
  border-top-color: #666;
}
.Select.Select--rtl {
  direction: rtl;
  text-align: right;
}
.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}
.Select-control .Select-input:focus {
  outline: 0;
  background: #fff;
}
.Select--single > .Select-control .Select-value,
.Select-placeholder {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Select-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}
.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: 0;
  line-height: 17px;
  padding: 8px 0 12px;
  -webkit-appearance: none;
}
.is-focused .Select-input > input {
  cursor: text;
}
.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}
.Select-control:not(.is-searchable) > .Select-input {
  outline: 0;
}
.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 16px;
}
.Select-loading {
  -webkit-animation: Select-animation-spin 0.4s infinite linear;
  -o-animation: Select-animation-spin 0.4s infinite linear;
  animation: Select-animation-spin 0.4s infinite linear;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-right-color: #333;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 0.2s;
  -o-animation: Select-animation-fadeIn 0.2s;
  animation: Select-animation-fadeIn 0.2s;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px;
}
.Select-clear-zone:hover {
  color: #d0021b;
}
.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
}
.Select--multi .Select-clear-zone {
  width: 17px;
}
.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
}
.Select--rtl .Select-arrow-zone {
  padding-right: 0;
  padding-left: 5px;
}
.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}
.Select-control > :last-child {
  padding-right: 5px;
}
.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}
.Select .Select-aria-only {
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  float: left;
}
@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}
.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}
.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.Select-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.Select-option.is-selected {
  background-color: #f5faff;
  background-color: rgba(0, 126, 255, 0.04);
  color: #333;
}
.Select-option.is-focused {
  background-color: #ebf5ff;
  background-color: rgba(0, 126, 255, 0.08);
  color: #333;
}
.Select-option.is-disabled {
  color: #ccc;
  cursor: default;
}
.Select-noresults {
  box-sizing: border-box;
  color: #999;
  cursor: default;
  display: block;
  padding: 8px 10px;
}
.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0;
}
.Select--multi.Select--rtl .Select-input {
  margin-left: 0;
  margin-right: 10px;
}
.Select--multi.has-value .Select-input {
  margin-left: 5px;
}
.Select--multi .Select-value {
  background-color: #ebf5ff;
  background-color: rgba(0, 126, 255, 0.08);
  border-radius: 2px;
  border: 1px solid #c2e0ff;
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #007eff;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
}
.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle;
}
.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px;
}
.Select--multi a.Select-value-label {
  color: #007eff;
  cursor: pointer;
  text-decoration: none;
}
.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}
.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c2e0ff;
  border-right: 1px solid rgba(0, 126, 255, 0.24);
  padding: 1px 5px 3px;
}
.Select--multi .Select-value-icon:focus,
.Select--multi .Select-value-icon:hover {
  background-color: #d8eafd;
  background-color: rgba(0, 113, 230, 0.08);
  color: #0071e6;
}
.Select--multi .Select-value-icon:active {
  background-color: #c2e0ff;
  background-color: rgba(0, 126, 255, 0.24);
}
.Select--multi.Select--rtl .Select-value {
  margin-left: 0;
  margin-right: 5px;
}
.Select--multi.Select--rtl .Select-value-icon {
  border-right: none;
  border-left: 1px solid #c2e0ff;
  border-left: 1px solid rgba(0, 126, 255, 0.24);
}
.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333;
}
.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}
.Select--multi.is-disabled .Select-value-icon:active,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:hover {
  background-color: #fcfcfc;
}
@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
.basic-multi-select div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.select-size-250 {
  flex-basis: 35%;
}
.select-size-300 {
  flex-basis: 50%;
}
.select-size-400 {
  flex-basis: 75%;
}
.select-size-500 {
  flex-basis: 100%;
}
.basic-multi-select .select__menu{
  z-index: 999999;
}
.DateRangePicker_picker{
  z-index: 999999 !important;
}

.ant-notification{
  z-index: 1062 !important;
}